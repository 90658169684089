import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Toolbar
} from "@mui/material";
import React from "react";

const PageSkeleton = () => {
  const tableContainerStyle = {
    width: "100%",
    overflowX: "auto"
  };

  const textColumnStyle = {
    display: "none" // Hide the text column on mobile
  };
  const rows = Array.from({ length: 6 });

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            borderBottom: "1px solid #E4E4E4",
            paddingBottom: "10px"
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={5} xs={12}>
              {/* Replace 'Item' with your actual component */}
              <div>
                <Skeleton variant='text' sx={{ height: "30px" }} />
              </div>
              <div>
                <Skeleton variant='text' sx={{ height: "20px" }} />
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              style={{
                marginLeft: "auto",
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center"
              }}
            >
              {/* Replace 'Item' with your actual component */}
              <div>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: "150px",
                    borderRadius: "20px",
                    height: "40px"
                  }}
                />
              </div>
              <div>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: "150px",
                    borderRadius: "20px",
                    height: "40px"
                  }}
                />
              </div>
              <div>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: "100px",
                    borderRadius: "20px",
                    height: "40px"
                  }}
                />
              </div>
              <div>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: "80px",
                    borderRadius: "20px",
                    height: "40px"
                  }}
                />
              </div>
              <div>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: "50px",
                    borderRadius: "20px",
                    height: "40px"
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ borderBottom: "solid 2px #D3D3D3" }}>
          <Grid item xs={12} md={8} lg={7}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                margin: "5px 0px",
                padding: "4px 20px"
              }}
            >
              <div>
                <Skeleton
                  variant='text'
                  sx={{ width: "130px", height: "40px" }}
                />
              </div>
              <div>
                <Skeleton
                  variant='text'
                  sx={{ width: "130px", height: "40px" }}
                />
              </div>
              <div>
                <Skeleton
                  variant='text'
                  sx={{ width: "130px", height: "40px" }}
                />
              </div>
              <div>
                <Skeleton
                  variant='text'
                  sx={{ width: "130px", height: "40px" }}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: {
                md: "end",
                lg: "end",
                xs: "center"
              },
              padding: "5px 20px"
            }}
          >
            <Skeleton
              variant='rectangular'
              sx={{
                width: "100px",
                height: "40px",
                borderRadius: "20px"
              }}
            />
          </Grid>
        </Grid>
        <div style={{ width: "100%", marginTop: "20px" }}>
          <Grid
            container
            sx={{
              boxSizing: "border-box",
              padding: "5px",
              width: "100%",
              alignItems: "center"
            }}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              sx={{ padding: "5px", boxSizing: "border-box" }}
            >
              {/* Replace 'TableSkeleton' with your actual component */}
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableBody>
                    {rows.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ padding: "5px", boxSizing: "border-box" }}
            >
              {/* Replace 'TableSkeleton' with your actual component */}
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableBody>
                    {rows.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div>
                            <Skeleton />
                          </div>
                        </TableCell>

                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              sx={{
                padding: "5px",
                boxSizing: "border-box",
                minHeight: "300px",
                maxHeight: "320px",
                overflow: "hidden",
                marginTop: "30px"
              }}
            >
              {/* Replace 'TableSkeleton' with your actual component */}

              <Toolbar
                sx={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "300px",
                  width: "100%"
                }}
              >
                <Skeleton
                  variant='circle'
                  sx={{ width: "70px", height: "40px" }}
                />

                <Skeleton
                  variant='rectangular'
                  sx={{ width: "100px", height: "5px" }}
                />
                <Skeleton
                  variant='rectangular'
                  sx={{ width: "100px", height: "5px" }}
                />
                <Skeleton
                  variant='circle'
                  sx={{ width: "70px", height: "40px" }}
                />
              </Toolbar>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              boxSizing: "border-box",
              padding: "10px",
              width: "100%"
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{ padding: "5px", boxSizing: "border-box" }}
            >
              {/* Replace 'TableSkeleton' with your actual component */}
              {/* Replace 'TableSkeleton' with your actual component */}
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableBody>
                    {rows.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{ padding: "5px", boxSizing: "border-box" }}
            >
              {/* Replace 'TableSkeleton' with your actual component */}
              {/* Replace 'TableSkeleton' with your actual component */}
              <TableContainer component={Paper} style={tableContainerStyle}>
                <Table>
                  <TableBody>
                    {rows.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>

                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant='text' />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default PageSkeleton;
