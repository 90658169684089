import "./style.css";
import React, { useState } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";

const Links = [
  { href: "#home", text: "Home" },

  { href: "#features", text: "Features" },

  {
    href: "#reviews",
    text: "REVIEW AUTOMATION"
  },

  { href: "#contact", text: "Contact" }
];

function Navbar() {
  const location = useLocation();
  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <AppBar
      style={{
        backgroundColor: "#fff",
        width: "100%",
        boxShadow:
          "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingX: {
            lg: "6rem",
            xs: "2rem"
          },
          paddingY: "10px",
          boxSizing: "border-box",
          padding: { md: "6px 0px", xs: "7px 0px" },
          width: "100%",
          position: "relative",
          gap: {
            md: "10px",
            xs: "50%"
          }
        }}
      >
        {/* Logo and Desktop Navigation Links */}
        <Toolbar sx={{ justifySelf: "start" }}>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "#4361EE",
              textTransform: "uppercase",
              fontWeight: "1000",
              fontSize: "1.7rem",
              fontFamily: "satoshi"
            }}
          >
            ECHO
          </Link>
        </Toolbar>
        {/* ... (same as before) */}
        <Toolbar sx={{ gap: "40px", display: { xs: "none", md: "flex" } }}>
          {Links.map((item, i) => (
            <a
              style={{
                textDecoration: "none",
                fontSize: "1rem",
                fontWeight: "500",
                textTransform: "uppercase",
                fontFamily: "satoshi",
                color: "#4361EE",
                borderBottom: location.hash?.includes(item?.href)
                  ? "2px solid #4361EE"
                  : "none"
              }}
              href={item.href}
              className={`${
                location.hash?.includes(item?.href) ? "activeLink" : "base_link"
              }`}
            >
              {item.text}
            </a>
          ))}
        </Toolbar>

        {/* Desktop Login/Signup Buttons */}
        <Toolbar sx={{ gap: "10px", display: { md: "flex", xs: "none" } }}>
          <Link
            to='/auth/login'
            style={{
              width: "fit",
              textTransform: "uppercase",
              padding: "7px  18px",
              color: "#4361EE",
              border: "1px solid #4361EE",
              textDecoration: "none",
              backgroundColor: "transparent",
              fontSize: ".925",
              borderRadius: "100px",
              fontFamily: "satoshi"
            }}
            className='buttonLogin'
          >
            Login
          </Link>
          <Link
            to={"/auth/register"}
            style={{
              width: "fit",
              textTransform: "uppercase",
              padding: "7px 18px",
              color: "#ffff",
              border: "1px solid #4361EE",
              textDecoration: "none",
              backgroundColor: "#4361EE",
              fontSize: ".925rem",
              borderRadius: "100px",
              fontFamily: "satoshi"
            }}
            className='button'
          >
            Sign up
          </Link>
        </Toolbar>

        {/* Mobile Hamburger/Cross Menu Button with Transition */}
        <Toolbar
          sx={{ display: { md: "none", xs: "flex" }, justifySelf: "end" }}
        >
          {showNavbar ? (
            <RxCross1
              onClick={() => setShowNavbar(!showNavbar)}
              style={{
                color: "black",
                fontSize: "30px",
                transition: "color 0.3s ease-in-out",
                cursor: "pointer"
              }}
            />
          ) : (
            <GiHamburgerMenu
              onClick={() => setShowNavbar(!showNavbar)}
              style={{
                color: "black",
                fontSize: "30px",
                transition: "color 0.3s ease-in-out",
                cursor: "pointer"
              }}
            />
          )}
        </Toolbar>

        {/* Mobile Navigation Menu with Animation */}
        <Toolbar
          className={`${showNavbar ? "glassmorphism" : ""} ${
            showNavbar ? "show" : "hide"
          }`}
          sx={{
            flexDirection: "column",
            position: "absolute",
            top: showNavbar ? "0" : "-100vh", // Slide in/out animation
            width: "100%",
            height: "100vh",
            transition: "all 0.8s ease-in-out",
            zIndex: "1",
            placeContent: "center",
            gap: "3rem",
            display: { md: "none", xs: "flex" }
          }}
        >
          <RxCross1
            onClick={() => setShowNavbar(!showNavbar)}
            style={{
              color: "black",
              fontSize: "30px",
              transition: "color 0.3s ease-in-out",
              cursor: "pointer",
              position: "absolute",
              right: "45px",
              top: "20px"
            }}
          />
          <Toolbar style={{ gap: "40px", flexDirection: "column" }}>
            {Links.map((item, key) => (
              <a
                key={key}
                style={{
                  textDecoration: "none",
                  fontSize: "1.2rem",
                  fontWeight: "800",
                  textTransform: "uppercase",
                  fontFamily: "satoshi"
                }}
                onClick={() => setShowNavbar(!showNavbar)}
                href={item.href}
                className={`${
                  location.hash === item.href ? "activeLink" : "base_link"
                }`}
              >
                {item.text}
              </a>
            ))}
          </Toolbar>
          <Toolbar style={{ gap: "20px", flexDirection: "column" }}>
            {/* ... (Mobile login/signup buttons) */}
            <Link
              to='/auth/login'
              target='_blank'
              style={{
                width: "fit",
                textTransform: "uppercase",
                padding: "8px  18px",
                color: "#4361EE",
                border: "1px solid #4361EE",
                textDecoration: "none",
                backgroundColor: "transparent",
                fontSize: "1.2rem",
                borderRadius: "100px",
                fontFamily: "satoshi"
              }}
              onClick={() => setShowNavbar(!showNavbar)}
              className='buttonLogin'
            >
              Login
            </Link>
            <Link
              to={"/auth/register"}
              target='_blank'
              style={{
                width: "fit",
                textTransform: "uppercase",
                padding: "8px 18px",
                color: "#ffff",
                border: "1px solid #4361EE",
                textDecoration: "none",
                backgroundColor: "#4361EE",
                fontSize: "1.2rem",
                borderRadius: "100px",
                fontFamily: "satoshi"
              }}
              onClick={() => setShowNavbar(!showNavbar)}
              className='button'
            >
              Signup
            </Link>
          </Toolbar>
        </Toolbar>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
