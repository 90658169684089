import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Modal,
  Paper
} from "@mui/material";
import { Base_Url } from "../component/Services/ApiFile";

const UnsubscribePage = () => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState("");

  const handleEmailChange = e => {
    setEmail(e.target.value);
    // Clear email format error when user starts typing
    setEmailFormatError("");
  };

  const handleUnsubscribe = async () => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setEmailFormatError("Email cannot be empty");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailFormatError("Invalid email format");
      return;
    }

    console.log(`Unsubscribe request for email: ${email}`);
    try {
      const response = await fetch(Base_Url + "/info/beta-email-collector", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: email,
          additional_text: "Custom additional text if needed",
          un_subscribe: true
        })
      });

      if (response.ok) {
        // Handle success, for example, open the success modal
        setModalOpen(true);
      } else {
        // Handle errors, you can show an error message to the user
        console.error("Failed to send unsubscribe request");
      }
    } catch (error) {
      console.error("Error sending unsubscribe request:", error);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Container maxWidth='sm'>
      <Box mt={5}>
        <Typography variant='h4' align='center' gutterBottom>
          Unsubscribe
        </Typography>
        <Typography variant='body1' align='center' paragraph>
          Enter your email to unsubscribe from our mailing list.
        </Typography>
        <TextField
          label='Email'
          variant='outlined'
          fullWidth
          margin='normal'
          value={email}
          onChange={handleEmailChange}
          error={Boolean(emailFormatError)}
          helperText={emailFormatError}
        />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleUnsubscribe}
        >
          Unsubscribe
        </Button>
      </Box>

      {/* Unsubscribe Success Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby='unsubscribe-modal-title'
        aria-describedby='unsubscribe-modal-description'
      >
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "400px",
            textAlign: "center"
          }}
        >
          <Typography variant='h6' id='unsubscribe-modal-title'>
            Unsubscribe Successful
          </Typography>
          <Typography
            variant='body1'
            id='unsubscribe-modal-description'
            paragraph
          >
            You have been successfully unsubscribed from our mailing list.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={handleModalClose}
          >
            Close
          </Button>
        </Paper>
      </Modal>
    </Container>
  );
};

export default UnsubscribePage;
