export const marketplace = [
  {
    market_id: "05984c7a017c4d7ba283a26db214818d",
    market_name: "OverStock",
    market_img_url:
      "https://www.kindpng.com/picc/m/153-1531849_bed-bath-and-beyond-clip-art-hd-png.png",
    marketplace_region: "US",
    market_url: "https://www.bedbathandbeyond.com/",
    prev_market_id: "PSUS"
  },
  {
    market_id: "05aa8dd7b8784c5b83d52d6402cf4a26",
    market_name: "Amazon DE",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "SG",
    market_url: "https://www.amazon.de/",
    prev_market_id: "PADE"
  },
  {
    market_id: "0be1f39fb6f149c3a57448ca95009fc3",
    market_name: "Amazon AU",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "AU",
    market_url: "https://www.amazon.com.au/",
    prev_market_id: "PAAU"
  },
  {
    market_id: "0d2dd2be140d4913a821ad593be73f55",
    market_name: "G2",
    market_img_url:
      "https://company.g2.com/hs-fs/hubfs/brand-guide/reversed-g2@2x.png?width=300&name=reversed-g2@2x.png",
    marketplace_region: "US",
    market_url: "https://www.g2.com/",
    prev_market_id: "PVUS"
  },
  {
    market_id: "1779c6363f3a474c9aba78dad7228d7d",
    market_name: "Purplle",
    market_img_url:
      "https://media4.ppl-media.com/mediafiles/ecomm/misc/ppl-icon-1024x1024.jpg",
    marketplace_region: "IN",
    market_url: "https://www.purplle.com/",
    prev_market_id: "PEIN"
  },
  {
    market_id: "1c5310c3d8094e5db6fc415ded6930d3",
    market_name: "BigBasket",
    market_img_url:
      "https://www.bbassets.com/monsters-inc/static/be1f00c92cf43c5b36397fe28c88a793.svg",
    marketplace_region: "IN",
    market_url: "https://www.bigbasket.com/",
    prev_market_id: "PHIN"
  },
  {
    market_id: "29f2b49f57044b4992702c42b99293a5",
    market_name: "Shopee ID",
    market_img_url:
      "https://seeklogo.com/images/S/shopee-logo-065D1ADCB9-seeklogo.com.png",
    marketplace_region: "ID",
    market_url: "https://shopee.co.id/",
    prev_market_id: "PXID"
  },
  {
    market_id: "2dcba1e595b54ec6b2f3abce8d0f6eb4",
    market_name: "Evo",
    market_img_url:
      "https://i.pinimg.com/originals/f1/a3/a2/f1a3a2c52ca624e702f32b084f9a4518.jpg",
    marketplace_region: "US",
    market_url: "https://www.evo.com/",
    prev_market_id: "PBDUS"
  },
  {
    market_id: "3034d09b713e46c69eed1819d294d84b",
    market_name: "Flipkart",
    market_img_url:
      "https://logos-world.net/wp-content/uploads/2020/11/Flipkart-Emblem.png",
    marketplace_region: "IN",
    market_url: "https://www.flipkart.com/",
    prev_market_id: "PCIN"
  },
  {
    market_id: "3577e2a38d78465c8f0f7fc3cd2de13e",
    market_name: "Amazon FR",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "AU",
    market_url: "https://www.amazon.fr/",
    prev_market_id: "PAFR"
  },
  {
    market_id: "3d52e8b4ef8d4ad69bfdc96d1d814c0f",
    market_name: "Tokopedia",
    market_img_url:
      "https://seeklogo.com/images/T/tokopedia-logo-40654CCDD6-seeklogo.com.png",
    marketplace_region: "ID",
    market_url: "https://www.tokopedia.com/",
    prev_market_id: "PWID"
  },
  {
    market_id: "4b579eef7a0d4aac9f7701b933c2f45d",
    market_name: "Walmart",
    market_img_url:
      "https://purepng.com/public/uploads/large/purepng.com-walmart-vertical-logologobrand-logoiconslogos-2515199388322jxhn.png",
    marketplace_region: "US",
    market_url: "https://www.walmart.com/",
    prev_market_id: "PNUS"
  },
  {
    market_id: "56057774992b45f285636bd4933f4e2f",
    market_name: "NetMeds",
    market_img_url:
      "https://www.netmeds.com/images/cms/aw_rbslider/slides/1704478767_1698777726_WebLogo-netmeds-new-logo.svg",
    marketplace_region: "IN",
    market_url: "https://www.netmeds.com/",
    prev_market_id: "PJIN"
  },
  {
    market_id: "58ecfa64db5b41b1a8e82208981805ad",
    market_name: "Amazon ES",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "ES",
    market_url: "https://www.amazon.es/",
    prev_market_id: "PAES"
  },
  {
    market_id: "64650dd0040d4ac084b4d3ef2445c416",
    market_name: "Lazada MY",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "MY",
    market_url: "https://www.lazada.com.my/",
    prev_market_id: "PUMY"
  },
  {
    market_id: "6edec1f2493247e29da58b5bfca68b24",
    market_name: "Jiomart",
    market_img_url:
      "https://seeklogo.com/images/J/jiomart-logo-DDF12BB25D-seeklogo.com.png",
    marketplace_region: "IN",
    market_url: "https://www.jiomart.com/",
    prev_market_id: "PZIN"
  },
  {
    market_id: "70eb60428d7b4875a1e01cc1eeb6659e",
    market_name: "Sephora",
    market_img_url:
      "https://1000logos.net/wp-content/uploads/2018/08/Sephora-Logo.png",
    marketplace_region: "US",
    market_url: "https://www.sephora.com/",
    prev_market_id: "PDUS"
  },
  {
    market_id: "76cd7ff5baef4b4585735d187437a4a6",
    market_name: "Sephora AU",
    market_img_url:
      "https://1000logos.net/wp-content/uploads/2018/08/Sephora-Logo.png",
    marketplace_region: "AU",
    market_url: "https://www.sephora.com.au/",
    prev_market_id: "PDAU"
  },
  {
    market_id: "77810d5232f14722bd10a8575cef4f5a",
    market_name: "PetCo",
    market_img_url: "https://download.logo.wine/logo/Petco/Petco-Logo.wine.png",
    marketplace_region: "US",
    market_url: "https://www.petco.com/shop/en/petcostore",
    prev_market_id: "PPUS"
  },
  {
    market_id: "7c39efa2b46543ce855d16acc42f3f86",
    market_name: "Amazon US",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "US",
    market_url: "https://www.amazon.com/",
    prev_market_id: "PAUS"
  },
  {
    market_id: "7f8cc510023244289208a1a168986a12",
    market_name: "Lazada SG",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "SG",
    market_url: "https://www.lazada.sg/",
    prev_market_id: "PUSG"
  },
  {
    market_id: "8da179cf9e384ccca513b0cf07d62a62",
    market_name: "Rei",
    market_img_url:
      "https://banner2.cleanpng.com/20180813/hlf/kisspng-rei-logo-retail-brand-dick-s-sporting-goods-cpgbrokers-our-distributors-5b710525d211e5.1542812215341335418605.jpg",
    marketplace_region: "US",
    market_url: "https://www.rei.com/",
    prev_market_id: "PTUS"
  },
  {
    market_id: "8e07b80faab84f0aa2e1653a135f39ba",
    market_name: "Lowes",
    market_img_url:
      "https://logos-world.net/wp-content/uploads/2021/03/Lowes-Logo-1965-present.png",
    marketplace_region: "US",
    market_url: "https://www.lowes.com/",
    prev_market_id: "PQUS"
  },
  {
    market_id: "8ea519950dd540198518f0bfeb2d276f",
    market_name: "Amazon IN",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "IN",
    market_url: "https://www.amazon.in/",
    prev_market_id: "PAIN"
  },
  {
    market_id: "92ee3bde97434342881562438ded2a83",
    market_name: "Nykaa",
    market_img_url:
      "https://play-lh.googleusercontent.com/AvXAbVCcYfk5DlrIfg7B53JP8zNSZ7y5H8rBrt9LlPzRjfTA7MTNAS6kEcfIC72ms4cX",
    marketplace_region: "IN",
    market_url: "https://www.nykaa.com/",
    prev_market_id: "PBIN"
  },
  {
    market_id: "9e28d2a68ffb45629324d9bd28b6a64c",
    market_name: "Getsupp",
    market_img_url:
      "https://images.yourstory.com/cs/images/companies/Getsupp-1659005257633.jpg",
    marketplace_region: "IN",
    market_url: "https://www.getsupp.com/",
    prev_market_id: "PYIN"
  },
  {
    market_id: "9e5e3457ce534e57a9d592d4b955d940",
    market_name: "Amazon JP",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "JP",
    market_url: "https://www.amazon.co.jp/",
    prev_market_id: "PAJP"
  },
  {
    market_id: "a4f0464efed94974856992414a9f5007",
    market_name: "Amazon CA",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "CA",
    market_url: "https://www.amazon.ca/",
    prev_market_id: "PACA"
  },
  {
    market_id: "a9299b3cee0a468e830b2f04607740d5",
    market_name: "HealthKart",
    market_img_url:
      "https://www.pngkey.com/png/detail/520-5208886_healthkart-logo-png.png",
    marketplace_region: "IN",
    market_url: "https://www.healthkart.com/",
    prev_market_id: "PLIN"
  },
  {
    market_id: "aa999bffc1ad4064a53d0aea415a1cf2",
    market_name: "Myntra",
    market_img_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSEDfXrbEc3owgvqlif9vkidaPstZcRqLAeqlqp6N50A&usqp=CAU&ec=48600113",
    marketplace_region: "IN",
    market_url: "https://www.myntra.com/",
    prev_market_id: "PFIN"
  },
  {
    market_id: "b5c1a4a26e6e49f2a0cc556cc1db0335",
    market_name: "Ulta",
    market_img_url:
      "https://seeklogo.com/images/U/ulta-beauty-logo-38D1BCA1E5-seeklogo.com.png",
    marketplace_region: "US",
    market_url: "https://www.ulta.com/",
    prev_market_id: "PBEUS"
  },
  {
    market_id: "b61ffa84f2b1448c859429a780a4160d",
    market_name: "Amazon SG",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "SG",
    market_url: "https://www.amazon.sg/",
    prev_market_id: "PASG"
  },
  {
    market_id: "b842dcdc18a848a6a14bf002626808e8",
    market_name: "DTC",
    market_img_url: "",
    marketplace_region: "IN",
    market_url: "",
    prev_market_id: "PGIN"
  },
  {
    market_id: "550e8400e29b41d4a716446655440000",
    market_img_url:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNBfgpjgvuc5YpXmBj85pqH-1okU_wyNgTiQ&s",
    market_name: "Target",
    marketplace_region: "US",
    prev_market_id: "PTTUS"
  },
  {
    market_id: "bbe5175118df4705a7a6576a7e46c773",
    market_name: "1MG",
    market_img_url: "https://www.1mg.com/images/tata_1mg_logo.svg",
    marketplace_region: "IN",
    market_url: "https://www.1mg.com/",
    prev_market_id: "PIIN"
  },
  {
    market_id: "bda8fc3678344697a7f4d4b9f4db1777",
    market_name: "Lazada PH",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "PH",
    market_url: "https://www.lazada.com.ph/",
    prev_market_id: "PUPH"
  },
  {
    market_id: "be1f842b5eb043b09f460a87580a3c6c",
    market_name: "Amazon UK",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "UK",
    market_url: "https://www.amazon.co.uk/",
    prev_market_id: "PAUK"
  },
  {
    market_id: "be83c99870f54de598d863c0ff9ae20d",
    market_name: "Backcountry",
    market_img_url:
      "https://assets2.brandfolder.io/bf-boulder-prod/card_image_v3/collection/pk7ns5v6k6znjwt8n872hgc/20220810204902306892/backcountry-logo.png",
    marketplace_region: "US",
    market_url: "https://www.backcountry.com/",
    prev_market_id: "PBBUS"
  },
  {
    market_id: "c0be50b42481480495eb1dcc72c57fd0",
    market_name: "Chewy",
    market_img_url:
      "https://searchlogovector.com/wp-content/uploads/2018/09/chewy-inc-logo-vector.png",
    marketplace_region: "US",
    market_url: "https://www.chewy.com/",
    prev_market_id: "PMUS"
  },
  {
    market_id: "c618d51027444b09a777f0be15173af1",
    market_name: "Moosejaw",
    market_img_url:
      "https://seeklogo.com/images/M/moosejaw-logo-74026EAA31-seeklogo.com.png",
    marketplace_region: "US",
    market_url: "https://www.moosejaw.com/",
    prev_market_id: "PBCUS"
  },
  {
    market_id: "d07e586e224f4d8c8eb045c1a0253daa",
    market_name: "PetSmart",
    market_img_url:
      "https://download.logo.wine/logo/PetSmart/PetSmart-Logo.wine.png",
    marketplace_region: "US",
    market_url: "https://www.petsmart.com/",
    prev_market_id: "POUS"
  },
  {
    market_id: "d32c58f9e43a4e8d9a9ef467006499de",
    market_name: "Kohls",
    market_img_url:
      "https://logos-download.com/wp-content/uploads/2016/09/Kohls_logo_red-700x106.png",
    marketplace_region: "US",
    market_url: "https://www.kohls.com/",
    prev_market_id: "PBFUS"
  },
  {
    market_id: "d51f5e2976704ba8976975f0d69ceba2",
    market_name: "PharmEasy",
    market_img_url:
      "https://assets.pharmeasy.in/apothecary/images/logo_big.svg?dim=256x0",
    marketplace_region: "IN",
    market_url: "https://www.pharmeasy.in/",
    prev_market_id: "PKIN"
  },
  {
    market_id: "dbe8253f9ae643a0a4253f29c159a374",
    market_name: "Lazada ID",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "ID",
    market_url: "https://www.lazada.co.id/",
    prev_market_id: "PUID"
  },
  {
    market_id: "e90774219fb54217b05305d34cf44639",
    market_name: "Lazada VN",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "VN",
    market_url: "https://www.lazada.vn/",
    prev_market_id: "PUVN"
  },
  {
    market_id: "f401bcb4e2cb48779d6c60dc277bfa39",
    market_name: "Amazon IT",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "AE",
    market_url: "https://www.amazon.it/",
    prev_market_id: "PAIT"
  },
  {
    market_id: "fdf5ee40ba47429e9cf2963e07641b09",
    market_name: "Amazon AE",
    market_img_url:
      "https://www.freeiconspng.com/thumbs/amazon-icon/amazon-icon--socialmedia-iconset--uiconstock-0.png",
    marketplace_region: "AE",
    market_url: "https://www.amazon.ae/",
    prev_market_id: "PAAE"
  },
  {
    market_id: "feab5457b7084775a2e6534b55f698e3",
    market_name: "Lazada TH",
    market_img_url:
      "https://seeklogo.com/images/L/lazada-logo-D8D78A9569-seeklogo.com.png",
    marketplace_region: "TH",
    market_url: "https://www.lazada.co.th/",
    prev_market_id: "PUTH"
  }
];
