import { createSlice } from "@reduxjs/toolkit";

export const CategoryInsights = createSlice({
  name: "CategoryInsights",

  initialState: {
    marketSelect: [],
    brands: [],
    filters: {},
    marktingAspect: [],
    filterAspectOverview: null,
    brandFilterOverview: null,
    brandFilterFeedback: [],
    brandFilterMarketingAspect: [],
    filterAspectMarketingAspect: [],
    filterBrandsMarketingChannel: [],
    overViewData: {},
    overViewDataFilter: {},
    feedbackData: {},
    feedbackDataFilter: {},
    marketingAspectData: {},
    marketingAspectDataFilter: {},
    marketDataFilter: {},
    marketData: {},
    tab: 0,
    categoryData: {},
    category: {},
    marketList: []
  },

  reducers: {
    setMarketingAspect: (state, action) => {
      state.marktingAspect = action.payload;
    },
    setFiltersData: (state, action) => {
      state.filters = action.payload;
    },
    setMarketList: (state, action) => {
      state.marketList = action.payload;
    },
    setMarketSelect: (state, action) => {
      state.marketSelect = action.payload;
    },
    setOverViewData: (state, action) => {
      state.overViewData = action.payload;
    },
    setOverViewDataFilter: (state, action) => {
      state.overViewDataFilter = action.payload;
    },
    setFeedbackData: (state, action) => {
      state.feedbackData = action.payload;
    },
    setFeedbackDataFilter: (state, action) => {
      state.feedbackDataFilter = action.payload;
    },
    setMarketingAspectData: (state, action) => {
      state.marketingAspectData = action.payload;
    },
    setMarketingAspectDataFilter: (state, action) => {
      state.marketingAspectDataFilter = action.payload;
    },
    setMarketDataFilter: (state, action) => {
      state.marketDataFilter = action.payload;
    },
    setMarketData: (state, action) => {
      state.marketData = action.payload;
    },
    setFilterAspectOverview: (state, action) => {
      state.filterAspectOverview = action.payload;
    },
    setBrandFilterOverview: (state, action) => {
      state.brandFilterOverview = action.payload;
    },
    setBrandFilterFeedback: (state, action) => {
      state.brandFilterFeedback = action.payload;
    },
    setBrandFilterMarketingAspect: (state, action) => {
      state.brandFilterMarketingAspect = action.payload;
    },
    setFilterAspectMarketingAspect: (state, action) => {
      state.filterAspectMarketingAspect = action.payload;
    },
    setFilterBrandsMarketingChannel: (state, action) => {
      state.filterBrandsMarketingChannel = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setCateogry: (state, actions) => {
      state.category = actions.payload;
    },
    setBrands: (state, actions) => {
      state.brands = actions.payload;
    },
    setCateogryInfo: (state, action) => {
      state.categoryData = action.payload;
    },
    storeCleanup: (state, action) => ({
      ...state,
      marketSelect: [],
      brands: [],
      filters: {},
      marketingAspect: [],
      filterAspectOverview: null,
      brandFilterOverview: null,
      brandFilterFeedback: [],
      brandFilterMarketingAspect: [],
      filterAspectMarketingAspect: [],
      filterBrandsMarketingChannel: [],
      overViewData: {},
      overViewDataFilter: {},
      feedbackData: {},
      feedbackDataFilter: {},
      marketingAspectData: {},
      marketingAspectDataFilter: {},
      marketDataFilter: {},
      marketData: {},
      tab: 0,
      categoryData: {},
      marketList: []
    })
  }
});

export const {
  setMarketSelect,
  setFilterAspectOverview,
  setBrandFilterOverview,
  setBrandFilterFeedback,
  setBrandFilterMarketingAspect,
  setFilterAspectMarketingAspect,
  setFilterBrandsMarketingChannel,
  setTab,
  setOverViewData,
  setOverViewDataFilter,
  setFeedbackData,
  setFeedbackDataFilter,
  setMarketingAspectData,
  setMarketingAspectDataFilter,
  setMarketDataFilter,
  setMarketData,
  setCateogry,
  setBrands,
  setMarketingAspect,
  setFiltersData,
  setMarketList,
  setCateogryInfo,
  storeCleanup
} = CategoryInsights.actions;

export default CategoryInsights.reducer;
