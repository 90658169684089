import { createSlice } from "@reduxjs/toolkit";

export const SKUSlice = createSlice({
  name: "SKUAnalysis",
  initialState: {
    SKUAnalysisData: null,
    ProductList: null,
    ProductData: null,
    LineChartData: null,
    SKUSummary: null,
    analysisExist: false
  },
  reducers: {
    addSKUAnalysis: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.SKUAnalysisData = action.payload;
    },
    addProductList: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.ProductList = action.payload;
    },
    addProductData: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.ProductData = action.payload;
    },
    addLineChartData: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.LineChartData = action.payload;
    },
    addSKUSummary: (state, action) => {
      state.SKUSummary = action.payload;
    },
    addAnalysisExist: (state, action) => {
      state.analysisExist = action.payload;
    }
  }
});

export const {
  addSKUAnalysis,
  addProductList,
  addProductData,
  addLineChartData,
  addSKUSummary,
  addAnalysisExist
} = SKUSlice.actions;
export default SKUSlice.reducer;
