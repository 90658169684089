import React from "react";
import { Box, Toolbar, Typography } from "@mui/material";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { AiOutlineInstagram } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { AiFillMediumSquare } from "react-icons/ai";

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#191650",
        width: "100%",
        height: { xs: "270px", md: "200px" },
        padding: "4px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px"
      }}
    >
      <Toolbar style={{ alignItems: "center", marginTop: "30px" }}>
        <Typography
          style={{
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: "satoshi"
          }}
        >
          Your customers are talking. Are you listening?
        </Typography>
      </Toolbar>
      <Toolbar
        sx={{
          width: { sm: "80%", xs: "90%" },
          flexDirection: {
            xs: "column",
            sm: "row"
          },
          gap: { xs: "10px", md: "0px" },
          margin: "0 auto",
          padding: "20px 0px",
          justifyContent: "space-between",
          alignItems: "center",
          justifySelf: "end",
          aliginSelf: "end"
        }}
      >
        <Toolbar
          sx={{
            width: { sm: "80%", xs: "100%" },
            gap: "20px",
            justifyContent: { md: "between", xs: "center" },
            alignItems: "center",
            flexDirection: { md: "row", xs: "column" },
            paddingTop: "20px"
          }}
        >
          <Typography
            style={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: "bold",
              fontFamily: "satoshi"
            }}
          >
            © 2023 Echo. All rights reserved.
          </Typography>
          <Typography
            style={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: "400",
              fontFamily: "satoshi"
            }}
          >
            Privacy Policy
          </Typography>
          <Typography
            style={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: "400",
              fontFamily: "satoshi"
            }}
          >
            Term and Service
          </Typography>
        </Toolbar>
        <Toolbar
          style={{
            width: "50%",
            gap: "20px",
            justifyContent: "start",
            alignItems: "center",
            paddingTop: "20px",
            marginTop: "20px"
          }}
        >
          <AiFillMediumSquare
            style={{ fontSize: "30px", color: "white", fontWeight: "400" }}
            className='socialMediaIcon'
          />
          <AiFillYoutube
            style={{ fontSize: "30px", color: "white", fontWeight: "400" }}
            className='socialMediaIcon'
          />
          <AiOutlineInstagram
            style={{ fontSize: "30px", color: "white", fontWeight: "400" }}
            className='socialMediaIcon'
          />
          <FaFacebookMessenger
            style={{ fontSize: "30px", color: "white", fontWeight: "400" }}
            className='socialMediaIcon'
          />
          <Link
            to={"https://www.linkedin.com/company/askecho/"}
            target='_blank'
          >
            <ImLinkedin
              style={{ fontSize: "20px", color: "white", fontWeight: "400" }}
              className='socialMediaIcon'
            />
          </Link>
        </Toolbar>
      </Toolbar>
    </Box>
  );
}

export default Footer;
