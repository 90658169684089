// amazon icons
import amazonIndia from "../../assets/images/amazonIndia_marketPlace.svg";
import amazonUk from "../../assets/images/amazonUk_marketPlace.svg";
import amazonUs from "../../assets/images/amazonUs_marketPlace.svg";
import amazonAE from "../../assets/images/AMAZON_AE.svg";
import amazonAU from "../../assets/images/AMAZON_AU.svg";
import amazonCA from "../../assets/images/AMAZON_CA.svg";
import amazonES from "../../assets/images/AMAZON_ES.svg";
import amazonJP from "../../assets/images/AMAZON_JP.svg";
import amazonSG from "../../assets/images/AMAZON_SG.svg";
import amazonDe from "../../assets/images/Amazon germany.svg";
import amazonIt from "../../assets/images/Amazon italy.svg";
import amaoznfr from "../../assets/images/Amazon France.svg";
//FLIPKART ICONS
import flipkartIndia from "../../assets/images/flipkartIndia.svg";
import flipkartUs from "../../assets/images/flipkart_Us.svg";
import flipkartUK from "../../assets/images/flipkartUK.svg";
import flipkartAU from "../../assets/images/FLIPKART_AU.svg";

// nyka icons
import NykaaIndia from "../../assets/images/nayka_india.svg";
import NykaaUS from "../../assets/images/NYKAA_US.svg";
import NykaaUK from "../../assets/images/NYKAA_UK.svg";

//PURPLLE ICONS
import PURPLLE_UK from "../../assets/images/PURPLLE_UK.svg";
import PURPLLE_US from "../../assets/images/PURPLLE_US.svg";
import purpleIndia from "../../assets/images/purple_india.svg";

//MYNTRA ICONS
import myantraIndia from "../../assets/images/myantra_india.svg";
import MYNTRA_UK from "../../assets/images/MYNTRA_UK.svg";
import MYNTRA_US from "../../assets/images/MYNTRA_US.svg";

// SEPHORA ICONS
import SEPHORA_AU from "../../assets/images/SEPHORA_AU.svg";
import SEPHORA_UK from "../../assets/images/SEPHORA_UK.svg";
import SEPHORA_US from "../../assets/images/SEPHORA_US.svg";

//OTHER MARKET ICONS
import HEALTHKART_IN from "../../assets/images/HEALTHKART_IN.svg";
import JIOMART_IN from "../../assets/images/JIOMART_IN.svg";
import KOHLS_US from "../../assets/images/KOHLS_US.svg";
import NETMEDS_IN from "../../assets/images/NETMEDS_IN.svg";
import NYKAA_UK from "../../assets/images/NYKAA_UK.svg";
import NYKAA_US from "../../assets/images/NYKAA_US.svg";
import PHARMEASY_IN from "../../assets/images/PHARMEASY_IN.svg";
import PLUM_IN from "../../assets/images/PLUM_IN.svg";
import TATA1MG_IN from "../../assets/images/TATA1MG_IN.svg";
import ULTA_US from "../../assets/images/ULTA_US.svg";
import BigBasket_In from "../../assets/images/BIGBASKET_IN.svg";
import Getsupp from "../../assets/images/Getsupp_icon.svg";
import walmartUs from "../../assets/images/walmart us.svg";
import LowesUs from "../../assets/images/Lowes us.svg";
import kapiva from "../../assets/images/Kapiva In.svg";
import bsc from "../../assets/images/Bsc IN.svg";
import plum from "../../assets/images/PLUM_IN.svg";
import Evo from "../../assets/images/Evo.svg";
import Rei from "../../assets/images/Rei.svg";
import Target from "../../assets/images/Target_US.svg";

export const marketplaceList = {
  "Amazon US": amazonUs,
  "Amazon IN": amazonIndia,
  "Amazon UK": amazonUk,
  Flipkart: flipkartIndia,
  "Flipkart UK": flipkartUK,
  "Flipkart US": flipkartUs,
  Purplle: purpleIndia,
  Nykaa: NykaaIndia,
  Myntra: myantraIndia,
  HealthKart: HEALTHKART_IN,
  Jiomart: JIOMART_IN,
  Kohls: KOHLS_US,
  NetMeds: NETMEDS_IN,
  PharmEasy: PHARMEASY_IN,
  "1MG": TATA1MG_IN,
  Ulta: ULTA_US,
  "Amazon AE": amazonAE,
  "Sephora AU": SEPHORA_AU,
  BigBasket: BigBasket_In,
  Getsupp: Getsupp,
  "Amazon SG": amazonSG,
  "Amazon CA": amazonCA,
  "Amazon JP": amazonJP,
  Lowes: LowesUs,
  "Amazon ES": amazonES,
  "Amazon AU": amazonAU,
  "Amazon DE": amazonDe,
  "Amazon IT": amazonIt,
  "Amazon FR": amaoznfr,
  Walmart: walmartUs,
  kapiva: kapiva,
  plum: plum,
  bsc: bsc,
  EVO: Evo,
  REI: Rei,
  "Target":Target
};

export const MarketData = [
  {
    region: "India",
    Seller: "https://sellercentral.amazon.in",
    Vendor: "https://www.vendorcentral.in",
    Market_id: "A21TJRUUN4KGV",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "US",
    Seller: "https://sellercentral.amazon.com",
    Vendor: "https://vendorcentral.amazon.com",
    Market_id: "ATVPDKIKX0DER",
    endpoint: "https://sellingpartnerapi-na.amazon.com"
  },
  {
    region: "Canada",
    Seller: "https://sellercentral.amazon.ca",
    Vendor: "https://vendorcentral.amazon.ca",
    Market_id: "A2EUQ1WTGCTBG2",
    endpoint: "https://sellingpartnerapi-na.amazon.com"
  },
  {
    region: "Mexico",
    Seller: "https://sellercentral.amazon.com.mx",
    Vendor: "https://vendorcentral.amazon.com.mx",
    Market_id: "A1AM78C64UM0Y8",
    endpoint: "https://sellingpartnerapi-na.amazon.com"
  },
  {
    region: "Brazil",
    Seller: "https://sellercentral.amazon.com.br",
    Vendor: "https://vendorcentral.amazon.com.br",
    Market_id: "A2Q3Y263D00KWC",
    endpoint: "https://sellingpartnerapi-na.amazon.com"
  },
  {
    region: "Spain",
    Seller: "https://sellercentral-europe.amazon.com",
    Vendor: "https://vendorcentral.amazon.es",
    Market_id: "A1RKKUPIHCS9HS",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "UK",
    Seller: "https://sellercentral-europe.amazon.com",
    Vendor: "https://vendorcentral.amazon.co.uk",
    Market_id: "A1F83G8C2ARO7P",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "France",
    Seller: "https://sellercentral-europe.amazon.com",
    Vendor: "https://vendorcentral.amazon.fr",
    Market_id: "A13V1IB3VIYZZH",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Netherlands",
    Seller: "https://sellercentral.amazon.nl",
    Vendor: "https://vendorcentral.amazon.nl",
    Market_id: "A1805IZSGTT6HS",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Germany",
    Seller: "https://sellercentral-europe.amazon.com",
    Vendor: "https://vendorcentral.amazon.de",
    Market_id: "A1PA6795UKMFR9",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Italy",
    Seller: "https://sellercentral-europe.amazon.com",
    Vendor: "https://vendorcentral.amazon.it",
    Market_id: "APJ6JRA9NG5V4",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Sweden",
    Seller: "https://sellercentral.amazon.se",
    Vendor: "https://vendorcentral.amazon.se",
    Market_id: "A2NODRKZP88ZB9",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "South Africa",
    Seller: "https://sellercentral.amazon.co.za",
    Vendor: "https://vendorcentral.amazon.co.za",
    Market_id: "AE08WJ6YKNBMC",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Poland",
    Seller: "https://sellercentral.amazon.pl",
    Vendor: "https://vendorcentral.amazon.pl",
    Market_id: "A1C3SOZRARQ6R3",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Egypt",
    Seller: "https://sellercentral.amazon.eg",
    Vendor: "https://vendorcentral.amazon.me",
    Market_id: "ARBP9OOSHTCHU",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Turkey",
    Seller: "https://sellercentral.amazon.com.tr",
    Vendor: "https://vendorcentral.amazon.com.tr",
    Market_id: "A33AVAJ2PDY3EV",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Saudi Arabia",
    Seller: "https://sellercentral.amazon.sa",
    Vendor: "https://vendorcentral.amazon.me",
    Market_id: "A17E79C6D8DWNP",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "U.A.E.",
    Seller: "https://sellercentral.amazon.ae",
    Vendor: "https://vendorcentral.amazon.me",
    Market_id: "A2VIGQ35RCS4UG",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },

  {
    region: "Belgium",
    Seller: "https://sellercentral.amazon.com.be",
    Vendor: "https://vendorcentral.amazon.com.be",
    Market_id: "AMEN7PMS3EDWL",
    endpoint: "https://sellingpartnerapi-eu.amazon.com"
  },
  {
    region: "Singapore",
    Seller: "https://sellercentral.amazon.sg",
    Vendor: "https://vendorcentral.amazon.com.sg",
    Market_id: "A19VAU5U5O7RUS",
    endpoint: "https://sellingpartnerapi-fe.amazon.com"
  },
  {
    region: "Australia",
    Seller: "https://sellercentral.amazon.com.au",
    Vendor: "https://vendorcentral.amazon.com.au",
    Market_id: "A39IBJ37TRP1C6",
    endpoint: "https://sellingpartnerapi-fe.amazon.com"
  },
  {
    region: "Japan",
    Seller: "https://sellercentral.amazon.co.jp",
    Vendor: "https://vendorcentral.amazon.co.jp",
    Market_id: "A1VC38T7YXB528",
    endpoint: "https://sellingpartnerapi-fe.amazon.com"
  }
];
