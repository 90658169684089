import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TablePagination,
  TextField,
  Typography
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { ImCross } from "react-icons/im";
import { FiEdit3 } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";

import {
  CloudUpload as CloudUploadIcon,
  UploadFile as UploadFileIcon
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import CustomDropdown from "../component/customComponent/NativeSelect";
import { CiSquareRemove } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../provider/context/userContext";
import { Base_Url } from "../component/Services/ApiFile";
import { useDashboardContext } from "../provider/context/dashboardContext";
import { marketplaceList } from "../component/Helper/Market_Image";
import { marketplace } from "../json/Marketplace";

function ExcelFileUpload() {
  const { marketsList, productList } = useDashboardContext();
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);
  const [marketList, setMarketList] = useState(["Amazon IN", "Flipkart"]);
  const [productName, setProductName] = useState("");
  const [productURLs, setProductURLs] = useState(["", ""]);
  const [excelUpload, setExcelUpload] = useState("Upload Manually");
  const [productData, setProductData] = useState({});
  const [selectEdit, setSelectEdit] = useState({});
  const globalContext = useGlobalContext();
  const [finalData, setFinalData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handler to open the dropdown
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // Handler to close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProductNameEdit = (e, productName) => {
    const value = e.target.value;
    if (productData) {
      setProductData(prevData => {
        const newData = { ...prevData };
        if (newData[productName]) {
          newData[value] = newData[productName];
          delete newData[productName];
        }
        return newData;
      });
    }
  };

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file) {
      console.log("File selected:", file.name);

      const reader = new FileReader();
      reader.readAsArrayBuffer(file); // Read file as an ArrayBuffer

      reader.onload = e => {
        const data = e.target.result;
        console.log("File reading started...");

        try {
          const workbook = XLSX.read(data, { type: "array" }); // Use 'array' type for efficiency
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          console.log("Parsed JSON data:", jsonData);

          const hasRequiredColumnsName = jsonData.some(
            row =>
              Object.keys(row).includes("Product Name") &&
              Object.keys(row)
                .filter(item => item !== "Product Name")
                .some(key => key.endsWith("_ProductUrls"))
          );

          if (hasRequiredColumnsName) {
            console.log("JSON data with required columns:", jsonData);
            setFileName(file.name);
            setFileData(jsonData); // Assuming setFileData is a state setter using useState
          } else {
            globalContext.Alert(
              "error",
              " Excel file must contain Product Name and Product URL columns."
            );
            // Handle the error (e.g., show an alert)
          }
        } catch (error) {
          console.error("Error reading Excel file:", error);
          // Handle the error gracefully (e.g., show an error message)
        }
      };
    } else {
      console.log("No file selected.");
    }
  };

  const handleProductNameChange = event => {
    setProductName(event.target.value);
  };

  const handleProductURLEdit = (e, index, productName) => {
    if (!productData) return;
    const value = e.target.value;
    // Create a copy of the current product data
    const updatedProductData = { ...productData };
    // Retrieve the URLs array for the specified product
    const urls = [...updatedProductData[productName]];
    // Update the URL at the specified index
    urls[index] = value;
    // Update the URLs array for the specified product in the copied data
    updatedProductData[productName] = urls;
    // Update the state with the modified product data
    setProductData(updatedProductData);
  };

  const handleProductURLChange = (index, event) => {
    const newProductURLs = [...productURLs];
    const url = event.target.value.toLowerCase(); // Convert URL to lowercase for case-insensitive comparison
    const urlParts = url.split("://"); // Split URL by "://"
    if (urlParts[0] === "https") {
      newProductURLs[index] = url;
      setProductURLs(newProductURLs);
    } else {
    }
  };

  const addProductURL = () => {
    setProductURLs([...productURLs, ""]); // Add an empty string for new URL input
  };

  const removeProductURL = index => {
    const newProductURLs = productURLs.filter((_, i) => i !== index);
    setProductURLs(newProductURLs);
  };

  const saveData = event => {
    // Validate input data
    if (!productName || productURLs.length === 0) {
      globalContext.Alert(
        "error",
        "Please enter at least one product URL and a product name."
      );
      return; // Early exit if validation fails
    }

    // Create a new product object with validated data
    const newProduct = { [productName]: productURLs }; // Use bracket notation for dynamic key

    // Update product data state using spread operator for immutability
    setProductData(prevData => ({ ...prevData, ...newProduct })); // Merge new product

    // Assuming you want to clear all URLs
    handleClick(event);
  };

  const handleDelete = (index, productName, type = "product") => {
    if (!productData) return;

    // Create a copy of the current product data
    const updatedProductData = { ...productData };

    if (type === "product") {
      // Remove the product entry from the copied data
      delete updatedProductData[productName];
    } else {
      // Remove the specified URL from the product's URLs array
      updatedProductData[productName] = updatedProductData[productName]?.filter(
        (item, idx) => idx !== index
      );
    }

    // Update the state with the modified product data
    setProductData(updatedProductData);
  };

  useEffect(() => {
    handleUploadClick();
  }, [fileData]);

  const handleUploadClick = event => {
    if (fileData) {
      const data = {};
      console.log(fileData);

      fileData?.map(item => {
        const index = Object.keys(data)?.findIndex(
          product_name =>
            product_name?.["Product Name"] === item["Product Name"]
        );
        if (index === -1) {
          const product = [];
          Object.keys(item)?.map(key => {
            const index = product?.findIndex(
              url => url?.toLowerCase() === item?.[key]?.toLowerCase()
            );
            if (index === -1 && key !== "Product Name") {
              product.push(item[key]);
            }
          });
          data[item?.["Product Name"]] = product;
        } else {
          const product = [];
          Object.keys(item)?.map(key => {
            const index = product?.findIndex(
              url => url?.toLowerCase() === item?.[key]?.toLowerCase()
            );
            if (index === -1 && key !== "Product Name") {
              product.push(item[key]);
            }
          });
          data[item?.["Product Name"]] = product;
        }
      });
      if (data) {
        setProductData(prevData => ({ ...prevData, ...data }));
      }

      globalContext.Alert("success", `File added successfully!`);
    } else {
    }
  };

  const sendData = async () => {
    // Perform upload actions here
    if (productData) {
      const requestBody = {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Token ${globalContext.userData.token}`
        }),
        body: JSON.stringify({
          product_data: productData,
          user_id: globalContext.userData.username
        })
      };
      try {
        const response = await fetch(
          Base_Url + "/scrape/scrape-on-demand",
          requestBody
        );
        const information = await response.json();
        if (response.ok) {
          globalContext.Alert(
            "success",
            "On Demand Request is procedding for" +
              " " +
              globalContext.userData.username +
              "We will notify on your email about this when it done"
          );
        }
      } catch (error) {
        globalContext.Alert("error", "Server Have some issue");
      }
    } else {
      alert("No data to send. Please add some data.");
    }
  };

  return (
    <Box
      display='flex'
      justifyContent='start'
      alignItems='center'
      sx={{ background: "white", flexDirection: "column", padding: "2rem" }}
    >
      <Box
        sx={{
          backgroundColor: "#f2f2f2",
          padding: "5px",
          marginBottom: "10px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          border: "1px solid #f2f2f2",
          borderRadius: "5px"
        }}
      >
        {" "}
        <CustomDropdown
          multiple={false}
          options={["Upload Manually", "Upload By Excel"]}
          title='All Time'
          onChange={setExcelUpload}
          value={excelUpload}
        />
      </Box>
      {excelUpload === "Upload Manually" && (
        <Card
          style={{
            width: "80%",
            padding: "20px",
            padding: "20px",
            background: "#fff", // Background color for the Popover content
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            border: "1px solid #f2f2f2",
            borderRadius: "20px",
            maxHeight: "450px"
          }}
        >
          <CardContent sx={{ maxHeight: "450px" }}>
            <Typography
              variant='h5'
              mb={2}
              style={{
                fontFamily: "satoshi",
                fontSize: "20px",
                fontWeight: "700",
                textAlign: "center",
                color: "#000"
              }}
            >
              Add Product Name and URL
            </Typography>

            {/* Product Name input */}
            <TextField
              label='Product Name'
              value={productName}
              onChange={handleProductNameChange}
              fullWidth
              margin='normal'
              sx={{
                fontFamily: "satoshi",
                fontWeight: "700",
                fontSize: ".925rem"
              }}
              autoComplete='off'
            />

            {/* Product URLs inputs */}
            <Grid container sx={{ marginY: "20px" }} spacing={2}>
              {productURLs?.map((url, index) => (
                <Grid
                  item
                  md={6}
                  key={index}
                  display='flex'
                  alignItems='center'
                  mb={2}
                  sx={{ justifyContent: "space-between", gap: "1rem" }}
                >
                  <TextField
                    label={`Enter ${
                      marketList[index] ? marketList[index] : ""
                    } Product Url`}
                    value={url}
                    onChange={e => handleProductURLChange(index, e)}
                    fullWidth
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".925rem",
                      height: "100%"
                    }}
                    autoComplete='off'
                  />
                  {/* <img
                                    style={{
                                        height: "30px",
                                        width: "50px"
                                        // marginTop: "10px",
                                    }}
                                    src={marketplaceList?.[marketList[index]]}
                                /> */}
                  {index !== 0 && (
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => removeProductURL(index)}
                      sx={{
                        background: "#6806B5",
                        fontSize: ".925rem",
                        fontWeight: "700",
                        padding: "10px 20px",
                        fontFamily: "satoshi",
                        color: "#ffff",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor: "#6806B5"
                        },
                        width: "50px"
                      }}
                    >
                      <RxCross2 style={{ fontSize: "1.5rem" }} />
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem"
              }}
            >
              {/* Add more URL button */}
              <Button
                variant='contained'
                onClick={addProductURL}
                sx={{
                  background: "#6806B5",
                  fontSize: ".925rem",
                  fontWeight: "700",
                  padding: "10px 20px",
                  fontFamily: "satoshi",
                  color: "#ffff",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "#6806B5"
                  }
                }}
              >
                Add More Product Url
              </Button>
              {/* <CustomDropdown
                            multiple={true}
                            options={marketplace?.map((item) => item?.market_name)}
                            title='Add Product Url'
                            onChange={()=>{

                            }}
                            value={marketList}
                        /> */}
            </Box>

            {/* Send button */}
          </CardContent>
        </Card>
      )}
      {excelUpload === "Upload By Excel" && (
        <Card
          style={{
            width: "80%",
            padding: "20px",
            padding: "20px",
            background: "#fff", // Background color for the Popover content
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            border: "1px solid #f2f2f2",
            borderRadius: "20px",
            marginTop: "2rem"
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {/* Title for the upload section */}
            <Typography
              variant='h5'
              mb={2}
              style={{
                fontFamily: "satoshi",
                fontSize: "20px",
                fontWeight: "700",
                textAlign: "center",
                color: "#000"
              }}
            >
              Upload Excel File of Product
            </Typography>

            {/* Warning message about required columns */}
            <Typography
              variant='body2'
              color='textSecondary'
              mb={2}
              style={{
                fontFamily: "satoshi",
                fontSize: "14px",
                fontWeight: "700",
                textAlign: "center",
                color: "red"
              }}
            >
              The Excel file must contain columns with headings: "Product Name"
              and ending with "ProductURL".
            </Typography>

            {/* File upload button with icon */}
            <Box mb={2} sx={{}}>
              <input
                accept='.xlsx, .xls'
                type='file'
                onChange={handleFileChange}
                style={{ display: "none" }}
                id='file-upload'
              />
              <label htmlFor='file-upload'>
                <Button
                  sx={{
                    background: "#ffff",
                    fontSize: "1rem",
                    fontWeight: "700",
                    padding: "10px 20px",
                    color: "#6806B5",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: "#ffff"
                    },
                    width: "180px"
                  }}
                  variant='contained'
                  component='span'
                  startIcon={<CloudUploadIcon />}
                >
                  Upload File
                </Button>
              </label>
            </Box>

            {/* Display the name of the uploaded file */}
            {fileName && (
              <Typography variant='body2' mb={2}>
                File Name: {fileName}
              </Typography>
            )}
          </CardContent>
        </Card>
      )}
      <Button
        variant='contained'
        color='primary'
        disabled={!finalData || !Object.entries(productData)?.length}
        onClick={sendData}
        sx={{
          background: "#6806B5",
          fontSize: ".925rem",
          fontWeight: "700",
          padding: "10px 20px",
          fontFamily: "satoshi",
          color: "#ffff",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#6806B5"
          },
          width: "250px",
          margin: "20px 0px",
          marginBottom: "60px"
        }}
      >
        Add New Product
      </Button>
      {productData && anchorEl && (
        <Box
          sx={{
            backdropFilter: "blur(2px)",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            maxHeight: "80vh",
            overflowY: "auto",
            position: "absolute",

            zIndex: "100"
          }}
        >
          <TableContainer
            className='sidebar-css_review'
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              overflowY: "auto"
            }}
          >
            <Table sx={{ maxWidth: "1440px", overflowX: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".875rem",
                      background: "#F4F4F6",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      borderLeft: ".5px solid #C6CAD2"
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        display: "flex",
                        minWidth: "100px",
                        width: "30%"
                      }}
                    >
                      Product Name
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".875rem",
                      background: "#F4F4F6",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      borderLeft: ".5px solid #C6CAD2"
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        display: "flex",
                        minWidth: "100px"
                      }}
                    >
                      {" "}
                      Product Urls
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".875rem",
                      background: "#F4F4F6",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      minWidth: "100px",
                      borderLeft: ".5px solid #C6CAD2",
                      width: "10%"
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(productData)
                  ?.slice(page * 10, (page + 1) * 10)
                  ?.map(([productName, productUrls], indexTop) => (
                    <TableRow>
                      <TableCell
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "left",
                          borderLeft: ".5px solid #C6CAD2"
                        }}
                      >
                        {selectEdit?.type === "product" &&
                        selectEdit?.index === indexTop ? (
                          <input
                            type='text'
                            value={productName}
                            onChange={e =>
                              handleProductNameEdit(e, productName)
                            }
                            key={`input-${productName}`}
                            style={{
                              width: "100%",
                              border: "0px",
                              outline: "none",
                              borderBottom: "1px solid black",
                              fontSize: "16px",
                              whiteSpace: "wrap"
                            }}
                          />
                        ) : (
                          productName
                        )}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "left",
                          borderLeft: ".5px solid #C6CAD2",
                          padding: "0px",
                          width: "60%",
                          maxWidth: "800px"
                        }}
                      >
                        {productUrls?.map((item, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              padding: "10px",
                              justifyContent: "space-between",
                              borderBottom:
                                productUrls?.length === index + 1
                                  ? "0px"
                                  : ".5px solid #C6CAD2"
                            }}
                          >
                            {selectEdit?.type === "product" &&
                            selectEdit?.index === indexTop ? (
                              <input
                                type='text'
                                value={item}
                                onChange={e =>
                                  handleProductURLEdit(e, index, productName)
                                }
                                key={`input-${index}`}
                                style={{
                                  width: "90%",
                                  border: "0px",
                                  outline: "none",
                                  borderBottom: "1px solid black",
                                  fontSize: "16px"
                                }}
                              />
                            ) : (
                              <p
                                style={{
                                  wordWrap: "break-word", // Set wordWrap to "break-word"
                                  width: "90%",
                                  whiteSpace: "normal",
                                  padding: "10px"
                                }}
                                key={`text-${index}`}
                              >
                                {item}
                              </p>
                            )}
                            <Button
                              onClick={() =>
                                handleDelete(index, productName, "productUrl")
                              }
                              sx={{ padding: "15px 10px" }}
                            >
                              <ImCross
                                style={{ fontSize: "1.2rem", color: "red" }}
                              />
                            </Button>
                          </Box>
                        ))}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "left",
                          borderLeft: ".5px solid #C6CAD2",
                          padding: "0px",

                          gap: "1rem"
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            height: "100%",
                            margin: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }}
                        >
                          {selectEdit?.type === "product" &&
                          selectEdit?.index === indexTop ? (
                            <Button
                              sx={{ padding: "10px 5px" }}
                              onClick={() => setSelectEdit(null)}
                            >
                              <FaCheck
                                style={{ fontSize: "1.5rem", color: "green" }}
                              />
                            </Button>
                          ) : (
                            <Button
                              sx={{ padding: "10px 5px" }}
                              onClick={() => {
                                setSelectEdit({
                                  type: "product",
                                  index: indexTop
                                });
                              }}
                            >
                              <FiEdit3
                                style={{ fontSize: "1.5rem", color: "blue" }}
                              ></FiEdit3>
                            </Button>
                          )}
                          <Button
                            onClick={() => handleDelete(indexTop, productName)}
                            sx={{ padding: "15px 10px" }}
                          >
                            <ImCross
                              style={{ fontSize: "1.2rem", color: "red" }}
                            />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {Object.entries(productData)?.length > 10 && (
              <TablePagination
                rowsPerPageOptions={[10]}
                component='div'
                count={Object.entries(productData)?.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
              />
            )}
            <Box>
              <Button
                variant='contained'
                color='primary'
                onClick={event => {
                  handleClose(event);
                  setSelectEdit(null);
                }}
                sx={{
                  background: "#6806B5",
                  fontSize: ".925rem",
                  fontWeight: "700",
                  padding: "10px 20px",
                  fontFamily: "satoshi",
                  color: "#ffff",
                  borderRadius: "10px",

                  "&:hover": {
                    backgroundColor: "#6806B5"
                  },
                  width: "250px",
                  margin: "20px 0px",
                  marginBottom: "60px",
                  marginX: "20px"
                }}
              >
                Close
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={event => {
                  if (
                    Object.values(productData)?.some(item => item?.length > 0)
                  ) {
                    const data = {};
                    Object.entries(productData)?.map(
                      ([productName, productURLs]) => {
                        if (productURLs?.length) {
                          data[productName] = productURLs;
                        }
                      }
                    );
                    // Reset input fields
                    setProductName("");
                    setProductURLs([""]);
                    setFinalData(data);
                    handleClose(event);
                    setSelectEdit(null);
                    // Display success message with optional username
                    globalContext.Alert(
                      "success",
                      `Product added successfully!`
                    );
                  }
                }}
                sx={{
                  background: "#6806B5",
                  fontSize: ".925rem",
                  fontWeight: "700",
                  padding: "10px 20px",
                  fontFamily: "satoshi",
                  color: "#ffff",
                  borderRadius: "10px",
                  marginX: "auto",
                  "&:hover": {
                    backgroundColor: "#6806B5"
                  },
                  width: "250px",
                  margin: "20px 0px",
                  marginBottom: "60px"
                }}
              >
                Save New Product
              </Button>
            </Box>
          </TableContainer>
        </Box>
      )}
      {productData &&
        Object.values(productData)?.every(item => item?.length) && (
          <TableContainer
            className='sidebar-css_review'
            sx={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              maxWidth: "1440px"
            }}
          >
            <Table sx={{ overflowX: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".875rem",
                      background: "#F4F4F6",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      borderLeft: ".5px solid #C6CAD2"
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        display: "flex",
                        minWidth: "100px",
                        maxWidth: "20%"
                      }}
                    >
                      Product Name
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "satoshi",
                      fontWeight: "700",
                      fontSize: ".875rem",
                      background: "#F4F4F6",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      borderLeft: ".5px solid #C6CAD2",
                      maxWidth: "80%",
                      minWidth: "80%"
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "start",
                        flexDirection: "column",
                        display: "flex",
                        minWidth: "100px",
                        width: "100%"
                      }}
                    >
                      {" "}
                      Product Urls
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(productData)
                  ?.slice(page * 10, (page + 1) * 10)
                  ?.map(([productName, productUrls], indexTop) => (
                    <TableRow key={productName}>
                      <TableCell
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "left",
                          borderLeft: ".5px solid #C6CAD2"
                        }}
                      >
                        {productName}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "satoshi",
                          fontWeight: "600",
                          fontSize: "14px",
                          textAlign: "left",
                          borderLeft: ".5px solid #C6CAD2",
                          padding: "0px",
                          maxWidth: "800px"
                        }}
                      >
                        {productUrls?.map((item, index) => (
                          <div
                            style={{
                              borderBottom:
                                productUrls?.length === index + 1
                                  ? "0px"
                                  : ".5px solid #C6CAD2",
                              padding: "10px",
                              wordWrap: "break-word", // Set wordWrap to "break-word"
                              width: "100%",
                              whiteSpace: "normal" // Set whiteSpace to "normal"
                            }}
                            key={`text-${index}`}
                          >
                            {item}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[10]}
              component='div'
              count={Object.entries(finalData)?.length}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        )}
    </Box>
  );
}

export default ExcelFileUpload;
