import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGlobalContext } from "../provider/context/userContext";
import Home from "../pages/Home";

const PrivateRoute = ({ children }) => {
  const globalContext = useGlobalContext(); // determine if authorized, from context or however you're doing it
  const location = useLocation();

  try {
    if (location.pathname === "/") {
      return globalContext.loginData?.username ? (
        <Navigate to='/dashboard' replace />
      ) : (
        <Home />
      );
    }
    return globalContext.loginData?.username ? children : <Home />;
  } catch (error) {
    return globalContext.loginData?.username ? children : <Home />;
  }
};

export default PrivateRoute;
