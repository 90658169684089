import React, { useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Container,
  Typography,
  FormHelperText,
  Grid,
  Paper,
  Box,
  CircularProgress
} from '@mui/material';
import { Base_Url } from "../component/Services/ApiFile";
import * as XLSX from "xlsx";

const ProductSearchForm = () => {
  const [formValues, setFormValues] = useState({
    keywords: '',
    region: 'India',
    numberOfProducts: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.keywords) {
      newErrors.keywords = 'Keywords are required';
    }
    if (!formValues.region) {
      newErrors.region = 'Region is required';
    }
    if (!formValues.numberOfProducts) {
      newErrors.numberOfProducts = 'Number of products is required';
    } else if (formValues.numberOfProducts <= 0) {
      newErrors.numberOfProducts = 'Must be a positive number';
    } else if (!Number.isInteger(Number(formValues.numberOfProducts))) {
      newErrors.numberOfProducts = 'Must be an integer';
    }
    return newErrors;
  };

  const convertToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, filename);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      const { keywords, region, numberOfProducts } = formValues;
      const keywordArray = keywords.split(',').map((keyword) => keyword.trim());
      console.log(keywords, region, numberOfProducts);
      
      setLoading(true); // Start the loader
      try {
        const response = await fetch(Base_Url + "/scrape/scrape-amazon-products-with-keywords", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            keywords: keywordArray,
            domain: region,
            num_products: numberOfProducts,
          }),
        });
        const data = await response.json();
        console.log('Response:', data);
        convertToExcel(data.products, `SOV.xlsx`);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false); // Stop the loader
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Product Search Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="keywords"
                name="keywords"
                label="Keywords"
                value={formValues.keywords}
                onChange={handleChange}
                error={Boolean(errors.keywords)}
                helperText={errors.keywords}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal" error={Boolean(errors.region)}>
                <InputLabel id="region-label">Region</InputLabel>
                <Select
                  labelId="region-label"
                  id="region"
                  name="region"
                  value={formValues.region}
                  onChange={handleChange}
                >
                  <MenuItem value="in">India</MenuItem>
                  <MenuItem value="us">US</MenuItem>
                </Select>
                {errors.region && <FormHelperText>{errors.region}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="numberOfProducts"
                name="numberOfProducts"
                label="Number of Products"
                type="number"
                value={formValues.numberOfProducts}
                onChange={handleChange}
                error={Boolean(errors.numberOfProducts)}
                helperText={errors.numberOfProducts}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ProductSearchForm;
