import { createSlice } from "@reduxjs/toolkit";

export const TrendSlice = createSlice({
  name: "TrendAnalysis",
  initialState: {
    tab: 0,
    npsScoreData: 0,
    reviewData: 0,
    topreviewData: 0,
    variantData: 0,
    type: 0,
    variantsSelected: [],
    marketSelected: [],
    userToken: "",
    masterProductId: "",
    productId: "",
    overviewData: [],
    marketTableData: [],
    marketPlaceData: [],
    market: "",
    productOverViewData: {},
    pricingData:[]
  },

  reducers: {
    addTabValue: (state, action) => {
      state.tab = action.payload;
    },
    addMasterProductId: (state, action) => {
      state.masterProductId = action.payload;
    },

    addVariant: (state, action) => {
      state.variantsSelected = action.payload;
    },
    addProductId: (state, action) => {
      state.productId = action.payload;
    },

    addMarket: (state, action) => {
      state.market = action.payload;
    },

    addProductOverviewData: (state, action) => {
      state.productOverViewData = action.payload;
    },

    addTopReview: (state, action) => {
      state.topreviewData = action.payload;
    },

    addnpsScoreData: (state, action) => {
      state.npsScoreData = action.payload;
    },

    addReviewData: (state, action) => {
      state.reviewData = action.payload;
    },

    addVariantData: (state, action) => {
      state.variantData = action.payload;
    },

    storeCleanupTrend: (state, action) => {
      state.masterProductId = "";
      state.productId = "";
      state.userToken = "";
    },

    addOverviewData: (state, action) => {
      state.overviewData = action.payload;
    },

    addMarketTableData: (state, action) => {
      state.marketTableData = action.payload;
    },

    addMarketPlaceData: (state, action) => {
      state.marketPlaceData = action.payload;
    },

    addMarketSelected: (state, action) => {
      state.marketSelected = action.payload;
    },

    addVariantSelected: (state, action) => {
      state.variantsSelected = action.payload;
    },
    
    addPricingData: (state, action) => {
      state.pricingData = action.payload;
    }
  }
});

export const {
  addTabValue,
  addVariant,
  addMarket,
  addMasterProductId,
  addProductId,
  storeCleanupTrend,
  addVariantData,
  addnpsScoreData,
  addReviewData,
  addTopReview,
  addOverviewData,
  addMarketTableData,
  addMarketPlaceData,
  addVariantSelected,
  addMarketSelected,
  addProductOverviewData,
  addPricingData
} = TrendSlice.actions;
export default TrendSlice.reducer;
