import { configureStore } from "@reduxjs/toolkit";
import SKUReducer from "./reducers/SKUanalysis";
import TextSKUReducer from "./reducers/textSKUAnalysis";
import ProgressReducer from "./reducers/progress";
import TrendReducer from "./reducers/trendAnalysis";
import brandReducer from "./reducers/brandAnalysis";
import feedbackReducer from "./reducers/feedbackAnalysis";
import categoryReducer from "./reducers/categoryAnalysis";
import CategoryInsights from "./reducers/CategoryInsights";

export const store = configureStore({
  reducer: {
    SKUAnalysis: SKUReducer,
    TextSKUAnalysis: TextSKUReducer,
    Progress: ProgressReducer,
    TrendAnalysis: TrendReducer,
    BrandAnalysis: brandReducer,
    FeedbackAnalysis: feedbackReducer,
    categoryAnalysis: categoryReducer,
    CategoryInsights: CategoryInsights
  }
});
