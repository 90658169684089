import { createSlice } from "@reduxjs/toolkit";

export const Progress = createSlice({
  name: "Progress",
  initialState: {
    progressData: null
  },
  reducers: {
    addProgressData: (state, action) => {
      // const newMovie = {id:3,title:'Batman'};
      state.progressData = action.payload;
    }
  }
});

export const { addProgressData } = Progress.actions;
export default Progress.reducer;
