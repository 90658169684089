import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  TablePagination,
  Tooltip,
  Grid
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import quickCommerceData from "../json/qcomm_data.json";
import AlertTable from "../component/Helper/PincodeAlert";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const PincodeTable = () => {
  const [showDetailTable, setShowDetailTable] = useState(false);
  const [marketLevelData, setMarketLevelData] = useState([]);
  const [skuLevelData, setSkuLevelData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCell, setIsHoveredCell] = useState(false);
  const [currentMarket, setCurrentMarket] = useState();


  const cellStyleData = {
    maxWidth: "130px",
    minWidth: "130px",
    width: "130px",
    textOverflow: "ellipsis",
    whiteSpace: "wrap",
    borderRight: "0px",
    borderLeft: "0px",
    fontSize: ".9rem",
    lineHeight: "24px",
    color: "#33415C",
    height: "56px",
    paddingY: "0px",
    fontWeight: "450",
    borderLeft:"1px solid lightgrey",
    cursor:"pointer"
  };
  
  const headerCellStyle = {
    ...cellStyleData,
    backgroundColor: "#F4F4F6",
    fontWeight: "500",
    fontSize: ".95rem",
    lineHeight: "20px",
    color: "#5C677D",
    height: "48px"
  };
    
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const pincodes = [
    110001, 400001, 600001, 700001, 500001, 560001, 380001, 122001, 641001,
    462001, 160001, 302001, 380015, 411001, 530001, 144001, 440001, 751001,
    380009, 600032, 110092, 121001, 641003, 382001, 570001, 686001, 682001,
    248001, 834001, 403001, 144411, 713201, 695001, 413001, 518001, 682020,
    400703, 396001, 744101, 679001, 751003, 421001, 628001, 500082, 585101,
    575001, 201001, 250001, 700034, 834002, 753001, 364001, 751005, 600020,
    682012, 414001, 474001, 517001, 517002, 411007, 401101, 785001, 180001,
    190001, 171001, 364002, 313001, 110003, 248002, 110044, 282001, 826001,
    141001, 586101, 400602, 190004, 401107, 180005, 226001, 273001, 282007,
    141003, 411005, 682011, 585102, 500013, 682014, 411004, 110002, 751002,
    641004, 380002, 560002, 380003, 144003, 751006, 110011, 110010, 122002
  ];
  const downloadPinCode = (numberofPinCodes) => {
    const selectedPinCodes = pincodes.slice(0, numberofPinCodes).map(pin => ({ 'Pin Codes': pin }));
    const worksheet = XLSX.utils.json_to_sheet(selectedPinCodes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pin Codes');
    XLSX.writeFile(workbook, 'pincodes.xlsx');
  };

  const textStyle = {
    color: isHovered ? "red" : "lightcoral",
    transition: "color 0.3s ease",
    cursor:'pointer'
  };

  const handleCloseDetailTable = () => {
    setShowDetailTable(false);
  };

  const handleDownload = (type) => {
    console.log("in download")
    const allKeys = [
      "SKU Name",
      "Swiggy-Instamart Url",
      "Blinkit Url",
      "Zepto Url",
      "Top Product",
      "Swiggy-Instamart (All Pincode Percent)",
      "Blinkit (All Pincode Percent)",
      "Zepto (All Pincode Percent)"
    ];
  
    const topKeys = [
      "SKU Name",
      "Swiggy-Instamart Url",
      "Blinkit Url",
      "Zepto Url",
      "Top Product",
      "Swiggy-Instamart (50 Pincode Percent)",
      "Blinkit (50 Pincode Percent)",
      "Zepto (50 Pincode Percent)"
    ];
  
    const keys = type === "all" ? allKeys : topKeys;
  
    const filteredData = skuLevelData.map(item => {
      const filteredItem = {};
      keys.forEach(key => {
        if (item.hasOwnProperty(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });
  
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "pincode_data.xlsx"
    );
  };
  

  const setSKULeveltable = (marketplace, topProduct, topPincode) => {
    let skuData = quickCommerceData.filter(
      item =>
        item[`${marketplace} Url`].length > 0 &&
        ((topProduct && item["Top Product"] === 1) || !topProduct) &&
        ((topPincode && item[`${marketplace} (Top 50 Pincode)`] === 1) ||
          !topPincode)
    );
    skuData.sort((a, b) => b["Top Product"] - a["Top Product"]);
    setSkuLevelData(skuData);
    setCurrentMarket(marketplace)
    setShowDetailTable(true);
  };

  useEffect(() => {
    let marketData = [
      {
        maketPlace: "Swiggy-Instamart",
        allPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] },
        topPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] }
      },
      {
        maketPlace: "Zepto",
        allPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] },
        topPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] }
      },
      {
        maketPlace: "Blinkit",
        allPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] },
        topPincodes: { allSKUs: [0, 0], topSKUs: [0, 0] }
      }
    ];

    quickCommerceData.forEach(sku => {
      if (sku["Swiggy-Instamart Url"].length > 0) {
        marketData[0].allPincodes.allSKUs[0]++;
        marketData[0].allPincodes.allSKUs[1] +=
          sku["Swiggy-Instamart (All Pincode Percent)"];
        if (sku["Top Product"] === 1) {
          marketData[0].allPincodes.topSKUs[0]++;
          marketData[0].allPincodes.topSKUs[1] +=
            sku["Swiggy-Instamart (All Pincode Percent)"];
        }
        if (sku["Swiggy-Instamart (Top 50 Pincode)"] === 1) {
          marketData[0].topPincodes.allSKUs[0]++;
          marketData[0].topPincodes.allSKUs[1] +=
            sku["Swiggy-Instamart (50 Pincode Percent)"];
          if (sku["Top Product"] === 1) {
            marketData[0].topPincodes.topSKUs[0]++;
            marketData[0].topPincodes.topSKUs[1] +=
              sku["Swiggy-Instamart (50 Pincode Percent)"];
          }
        }
      }
      if (sku["Blinkit Url"].length > 0) {
        marketData[2].allPincodes.allSKUs[0]++;
        marketData[2].allPincodes.allSKUs[1] +=
          sku["Blinkit (All Pincode Percent)"];
        if (sku["Top Product"] === 1) {
          marketData[2].allPincodes.topSKUs[0]++;
          marketData[2].allPincodes.topSKUs[1] +=
            sku["Blinkit (All Pincode Percent)"];
        }
        if (sku["Blinkit (Top 50 Pincode)"] === 1) {
          marketData[2].topPincodes.allSKUs[0]++;
          marketData[2].topPincodes.allSKUs[1] +=
            sku["Blinkit (50 Pincode Percent)"];
          if (sku["Top Product"] === 1) {
            marketData[2].topPincodes.topSKUs[0]++;
            marketData[2].topPincodes.topSKUs[1] +=
              sku["Blinkit (50 Pincode Percent)"];
          }
        }
      }
      if (sku["Zepto Url"].length > 0) {
        marketData[1].allPincodes.allSKUs[0]++;
        marketData[1].allPincodes.allSKUs[1] +=
          sku["Zepto (All Pincode Percent)"];
        if (sku["Top Product"] === 1) {
          marketData[1].allPincodes.topSKUs[0]++;
          marketData[1].allPincodes.topSKUs[1] +=
            sku["Zepto (All Pincode Percent)"];
        }
        if (sku["Zepto (Top 50 Pincode)"] === 1) {
          marketData[1].topPincodes.allSKUs[0]++;
          marketData[1].topPincodes.allSKUs[1] +=
            sku["Zepto (50 Pincode Percent)"];
          if (sku["Top Product"] === 1) {
            marketData[1].topPincodes.topSKUs[0]++;
            marketData[1].topPincodes.topSKUs[1] +=
              sku["Zepto (50 Pincode Percent)"];
          }
        }
      }
    });

    marketData.forEach(item => {
      item.allPincodes.allSKUs[1] = (
        item.allPincodes.allSKUs[1] / item.allPincodes.allSKUs[0]
      ).toFixed(0);
      item.allPincodes.topSKUs[1] = (
        item.allPincodes.topSKUs[1] / item.allPincodes.topSKUs[0]
      ).toFixed(0);
      item.topPincodes.allSKUs[1] = (
        item.topPincodes.allSKUs[1] / item.topPincodes.allSKUs[0]
      ).toFixed(0);
      item.topPincodes.topSKUs[1] = (
        item.topPincodes.topSKUs[1] / item.topPincodes.topSKUs[0]
      ).toFixed(0);
    });

    setMarketLevelData(marketData);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: 3, fontFamily: "Arial, sans-serif" }}>
      <Typography variant='h4' gutterBottom>
        Pin Code Statistics
      </Typography>
      <TableContainer component={Paper} style={{
              width: "100%",
              overflowX: "auto",
              border: "1px solid #C6CAD2",
              borderRadius: "8px",
              marginBottom:3
            }}>
        <Table>
          <TableHead >
            <TableRow>
              <TableCell rowSpan={1} align='center' sx={headerCellStyle}></TableCell>
              <TableCell colSpan={2} align='center' sx={headerCellStyle}>
                All PinCodes
              </TableCell>
              <TableCell colSpan={2} align='center' sx={headerCellStyle}>
                Top 50 Pincodes
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='left' sx={{borderLeft:"1px solid lightgrey"}}>Channel</TableCell>
              <TableCell align='center' sx={{borderLeft:"1px solid lightgrey"}}>All</TableCell>
              <TableCell align='center' sx={{borderLeft:"1px solid lightgrey"}}>Top 50 SKUs</TableCell>
              <TableCell align='center' sx={{borderLeft:"1px solid lightgrey"}}>All</TableCell>
              <TableCell align='center' sx={{borderLeft:"1px solid lightgrey"}}>Top 50 SKUs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marketLevelData.map((channel, index) => (
              <TableRow key={index}>
                <TableCell sx={cellStyleData}>{channel.maketPlace}</TableCell>
                <TableCell
                  align='center'
                  onClick={() =>
                    setSKULeveltable(channel.maketPlace, false, false)
                  }
                  sx={cellStyleData}
                >
                  {channel.allPincodes.allSKUs[0]}
                  <span
                    style={textStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    ({channel.allPincodes.allSKUs[1]}%)
                  </span>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() =>
                    setSKULeveltable(channel.maketPlace, true, false)
                  }
                  sx={cellStyleData}
                >
                  {channel.allPincodes.topSKUs[0]}
                  <span
                    style={textStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    ({channel.allPincodes.topSKUs[1]}%)
                  </span>
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() =>
                    setSKULeveltable(channel.maketPlace, false, true)
                  }
                  sx={cellStyleData}
                >
                  {channel.topPincodes.allSKUs[0]}
                  <span
                    style={textStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    ({channel.topPincodes.allSKUs[1]}%)
                  </span>
                </TableCell >
                <TableCell
                  align='center'
                  onClick={() =>
                    setSKULeveltable(channel.maketPlace, true, true)
                  }
                  sx={cellStyleData}
                >
                  {channel.topPincodes.topSKUs[0]}
                  <span
                    style={textStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    ({channel.topPincodes.topSKUs[1]}%)
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showDetailTable && skuLevelData.length > 0 && (
        <Box sx={{marginTop:"20px"}}>
          <TableContainer component={Paper} style={{
              width: "100%",
              overflowX: "auto",
              border: "1px solid #C6CAD2",
              borderRadius: "8px",
              marginBottom:3
            }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align='center' sx={headerCellStyle}>
                  <CancelOutlinedIcon
                  style={{
                    color: "grey",
                    height: "25px",
                    width: "25px",
                    float: "left"
                  }}
                  onClick={handleCloseDetailTable}/>
                  {currentMarket}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align='center'
                    sx={headerCellStyle}
                  >
                    All PinCodes
                    <FileDownloadOutlinedIcon
              style={{
                color: "#4361EE",
                fontSize: "12px",
                border: "1px solid #4361EE",
                height: "20px",
                width: "30px",
                borderRadius: "20px",
                padding: "1px",
                cursor: "pointer",
                marginLeft: "10px",
                float: "right"
              }}
              onClick={()=>handleDownload("all")}
            />
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align='center'
                    sx={headerCellStyle}
                  >
                    Top 50 Pincodes
                    <FileDownloadOutlinedIcon
              style={{
                color: "#4361EE",
                fontSize: "12px",
                border: "1px solid #4361EE",
                height: "20px",
                width: "30px",
                borderRadius: "20px",
                padding: "1px",
                cursor: "pointer",
                marginLeft: "10px",
                float: "right"
              }}
              onClick={()=>handleDownload("top")}
            />
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell>Brand</TableCell> */}
                  <TableCell width={"500px"}>SKU</TableCell>
                  <TableCell align='center' sx={{ borderLeft: "1px solid lightgrey" }}>Top SKU</TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Swiggy
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Blinkit
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Zepto
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Swiggy
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Blinkit
                  </TableCell>
                  <TableCell
                    align='center'
                    sx={{ borderLeft: "1px solid lightgrey" }}
                  >
                    Zepto
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {skuLevelData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((sku, index) => (
                    <TableRow key={index}>
                      <TableCell width={"500px"} sx={cellStyleData}>
                        <Tooltip title={sku["SKU Name"]}>
                          <span>
                            {sku["SKU Name"].length > 60
                              ? `${sku["SKU Name"].substring(0, 60)}...`
                              : sku["SKU Name"]}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='center' sx={cellStyleData}>
                        {sku["Top Product"] === 1 ? "Y" : "N"}
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Swiggy-Instamart (All Pincode Percent)"]>0?
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Swiggy-Instamart (All Pincode Percent)"])}
                              />: (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Swiggy-Instamart (All Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                        
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Blinkit (All Pincode Percent)"]>0? 
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Blinkit (All Pincode Percent)"])}
                              />: (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Blinkit (All Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                       
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Zepto (All Pincode Percent)"]>0 ?
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Zepto (All Pincode Percent)"])}
                              />: (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Zepto (All Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                        
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                        
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Swiggy-Instamart (50 Pincode Percent)"]>0 ?
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Swiggy-Instamart (50 Pincode Percent)"])}
                              />: (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Swiggy-Instamart (50 Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                        
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Blinkit (50 Pincode Percent)"]>0 ? 
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Blinkit (50 Pincode Percent)"]>0 )}
                              />
                              : (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Blinkit (50 Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                        
                      </TableCell>
                      <TableCell
                        align='center'
                        sx={cellStyleData}
                      >
                        
                          <Grid container>
                            <Grid item xs={3}>
                            {index < 3 && page===0 && sku["Zepto (50 Pincode Percent)"]>0 ? 
                              <DownloadIcon
                                sx={{
                                  color: "#1976d2",
                                  fontSize: "17px",
                                  position: "relative",
                                  top: "3px",
                                  cursor: "pointer !important"
                                }}
                                onClick={() => downloadPinCode(sku["Zepto (50 Pincode Percent)"])}
                              />: (
                                <></>
                              )}
                            </Grid>
                            <Grid item xs={9}>
                              {sku["Zepto (50 Pincode Percent)"]}%
                            </Grid>
                          </Grid>
                        
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={skuLevelData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        <AlertTable data={skuLevelData} pincodes={pincodes}/>
        </Box>
      )}
    </Box>
  );
};

export default PincodeTable;
