import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  TablePagination
} from "@mui/material";

const marketPlaces = ["Zepto", "Swiggy-Instamart", "Blinkit"];
const pinCodes = [
  110001, 400001, 600001, 700001, 500001, 560001, 380001, 122001, 641001,
 
];

const AlertTable = ({ data }) => {
  const [rows, setRows] = useState(
    data.map((sku) => ({
      skuName: sku["SKU Name"],
      marketPlace: [],
      pinCode: [],
      email: ""
    }))
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleMarketPlaceChange = (index, event) => {
    const { value } = event.target;
    const newRows = [...rows];
    newRows[index].marketPlace = typeof value === "string" ? value.split(",") : value;
    setRows(newRows);
  };

  const handlePinCodeChange = (index, event) => {
    const { value } = event.target;
    const newRows = [...rows];
    if (value.includes("Select All")) {
      if (newRows[index].pinCode.includes("Select All")) {
        newRows[index].pinCode = [];
      } else {
        newRows[index].pinCode = pinCodes.filter(code => code !== "Select All");
      }
    } else {
      newRows[index].pinCode = typeof value === "string" ? value.split(",") : value;
      // Deselect "Select All" if other options are selected
      if (newRows[index].pinCode.length !== pinCodes.length - 1) {
        newRows[index].pinCode = newRows[index].pinCode.filter(code => code !== "Select All");
      }
    }
    setRows(newRows);
  };

  const handleEmailChange = (index, event) => {
    const { value } = event.target;
    const newRows = [...rows];
    newRows[index].email = value;
    setRows(newRows);
  };

  const handleAddAlert = () => {
    // Add alert handling logic here
    console.log("Alerts Added", rows);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Typography variant='h4' gutterBottom textAlign='center'>
        Alert Management
      </Typography>
      <TableContainer component={Paper}>
        {rows.length > 0 && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">SKU Name</TableCell>
                  <TableCell align="center">Market Place</TableCell>
                  <TableCell align="center">PinCode</TableCell>
                  <TableCell align="center">Alert to</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell width={"500px"}>
                      <Tooltip title={row.skuName}>
                        <span>
                          {row.skuName.length > 60
                            ? `${row.skuName.substring(0, 60)}...`
                            : row.skuName}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant='outlined' sx={{ width: 200, height: 40 }}>
                        {!row.marketPlace.length ? (
                          <InputLabel sx={{ top: '50%', transform: 'translateY(-50%)' }}>Market Place</InputLabel>
                        ) : null}
                        <Select
                          multiple
                          value={row.marketPlace}
                          onChange={(event) => handleMarketPlaceChange(index, event)}
                          input={<OutlinedInput label='Market Place' />}
                          renderValue={(selected) => selected.join(", ")}
                          sx={{ height: 40 }}
                        >
                          {marketPlaces.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={row.marketPlace.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth variant='outlined' sx={{ width: 200, height: 40 }}>
                        {!row.pinCode.length ? (
                          <InputLabel sx={{ top: '50%', transform: 'translateY(-50%)' }}>PinCode</InputLabel>
                        ) : null}
                        <Select
                          multiple
                          value={row.pinCode}
                          onChange={(event) => handlePinCodeChange(index, event)}
                          input={<OutlinedInput label='PinCode' />}
                          renderValue={(selected) => selected.filter(code => code !== "Select All").join(", ")}
                          sx={{ height: 40 }}
                        >
                          {pinCodes.map((code) => (
                            <MenuItem key={code} value={code}>
                              <Checkbox checked={row.pinCode.indexOf(code) > -1} />
                              <ListItemText primary={code} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        variant='outlined'
                        fullWidth
                        value={row.email}
                        onChange={(event) => handleEmailChange(index, event)}
                        placeholder='Enter email'
                        sx={{ height: 40, width: 200 }}
                        inputProps={{ sx: { height: 40, padding: '0 14px' } }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
      <Box textAlign='center' mt={2}>
        <Button variant='contained' color='primary' onClick={handleAddAlert}>
          Add Alert
        </Button>
      </Box>
    </Box>
  );
};

export default AlertTable;
