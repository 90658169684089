import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useGlobalContext } from "./provider/context/userContext";
import Route from "./routes/route";

const App = () => {
  const globalContext = useGlobalContext();

  const darkTheme = createTheme({
    palette: {
      mode: globalContext.theme
    },
    typography: {
      fontFamily: "satoshi",
      fontWieght: "600",
      color: "#000" // Set default text color using theme palette (optional)
    }
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Route />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
