import { createSlice } from "@reduxjs/toolkit";

export const BrandAnalysisSlice = createSlice({
  name: "BrandAnalysis",
  initialState: {
    brandAnalysisData: null,
    brandAnalysisChangingData: null,
    summaryData: null,
    marketList: null,
    marketSelected: [],
    userFilterHas: false,
    filterSelected: [],
    filterData: [],
    userToken: "",
    reviewType: "",
    reviewtime: "",
    ratingNpsShow: "",
    npsScoreRange: "",
    includeNpsZero: "",
    ratingRange: "",
    includeZeroRating: "",
    ratingMarketTableData: "",
    ratingSeasonCategoryTableData: "",
    filter: false,
    npsType: "",
    npsTime: "",
    fitlerApplied: "",
    npsScoreFilterData: "",
    npsSeasonCategoryFilterData: "",
    bsrData: "",
    brandRiserGraphData: [],
    newBrandPieChartData: [],
    brandCategoryRiserGraphData: [],
    brandSubCategoryRiserGraphData: [],
    brandBrandRiserGraphData: []
  },

  reducers: {
    addBrandAnalysisData: (state, action) => {
      state.brandAnalysisData = action.payload;
    },
    addBrandRiserGraphData: (state, action) => {
      state.brandRiserGraphData = action.payload;
    },
    addBrandBrandRiserGraphData: (state, action) => {
      state.brandBrandRiserGraphData = action.payload;
    },
    addNewBrandPieChartData: (state, action) => {
      state.newBrandPieChartData = action.payload;
    },
    addBrandCategoryRiserGraphData: (state, action) => {
      state.brandCategoryRiserGraphData = action.payload;
    },
    addBrandSubCategoryTableData: (state, action) => {
      state.brandSubCategoryTableData = action.payload;
    },
    addBrandSubCategoryRiserGraphData: (state, action) => {
      state.brandSubCategoryRiserGraphData = action.payload;
    },
    addSummaryData: (state, action) => {
      state.summaryData = action.payload;
    },
    addBrandAnalysisChangingData: (state, action) => {
      state.brandAnalysisChangingData = action.payload;
    },
    addMarketList: (state, action) => {
      state.marketList = action.payload;
    },
    addUserFilterHas: (state, action) => {
      state.userFilterHas = action.payload;
    },
    addUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    addMarketSelected: (state, action) => {
      state.marketSelected = action.payload;
    },
    addReviewType: (state, action) => {
      state.reviewType = action.payload;
    },
    addReviewTime: (state, action) => {
      state.reviewtime = action.payload;
    },
    addRatingNpsShow: (state, action) => {
      state.ratingNpsShow = action.payload;
    },
    addNpsScoreRange: (state, action) => {
      state.npsScoreRange = action.payload;
    },
    addRatingRange: (state, action) => {
      state.ratingRange = action.payload;
    },
    addInculdeZeroRating: (state, action) => {
      state.includeZeroRating = action.payload;
    },
    addIncludeZeorNps: (state, action) => {
      state.includeNpsZero = action.payload;
    },
    addNpsTime: (state, action) => {
      state.npsTime = action.payload;
    },
    addNpsType: (state, action) => {
      state.npsType = action.payload;
    },
    addFilter: (state, action) => {
      state.filter = action.payload;
    },
    addRatingMarketTableData: (state, action) => {
      state.ratingMarketTableData = action.payload;
    },
    addSeasonCategoryTableData: (state, action) => {
      state.ratingSeasonCategoryTableData = action.payload;
    },
    addFilterApplied: (state, action) => {
      state.fitlerApplied = action.payload;
    },
    addNpsScorefilterData: (state, action) => {
      state.npsScoreFilterData = action.payload;
    },
    addNpsSeasonCategoryFilterData: (state, action) => {
      state.npsSeasonCategoryFilterData = action.payload;
    },
    addFilterSelected: (state, action) => {
      state.filterSelected = action.payload;
    },
    addFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    addBsrData: (state, action) => {
      state.bsrData = action.payload;
    },
    storeCleanUpBrand: (state, action) => {
      return {
        ...state,
        brandAnalysisData: null,
        brandAnalysisChangingData: null,
        summaryData: null,
        marketList: null,
        marketSelected: [],
        userFilterHas: false,
        filterSelected: [],
        filterData: [],
        userToken: null,
        reviewType: null,
        reviewtime: null,
        ratingNpsShow: null,
        npsScoreRange: null,
        includeNpsZero: null,
        ratingRange: null,
        includeZeroRating: null,
        ratingMarketTableData: null,
        ratingSeasonCategoryTableData: null,
        filter: null,
        npsType: null,
        npsTime: null,
        fitlerApplied: null,
        npsScoreFilterData: null,
        npsSeasonCategoryFilterData: null,
        bsrData: null,
        brandRiserGraphData: [],
        newBrandPieChartData: [],
        brandCategoryRiserGraphData: [],
        brandSubCategoryTableData: [],
        brandSubCategoryRiserGraphData: [],
        brandBrandRiserGraphData: []
      };
    }
  }
});
export const {
  addBrandAnalysisData,
  addUserToken,
  addSummaryData,
  addBrandAnalysisChangingData,
  addMarketList,
  addUserFilterHas,
  addMarketSelected,
  addFilter,
  addNpsTime,
  addNpsType,
  addIncludeZeorNps,
  addInculdeZeroRating,
  addRatingRange,
  addReviewTime,
  addReviewType,
  addRatingNpsShow,
  addNpsScoreRange,
  addRatingMarketTableData,
  addSeasonCategoryTableData,
  addFilterApplied,
  addNpsScorefilterData,
  addNpsSeasonCategoryFilterData,
  addFilterSelected,
  addFilterData,
  addBsrData,
  addBrandRiserGraphData,
  addNewBrandPieChartData,
  addBrandCategoryRiserGraphData,
  addBrandSubCategoryTableData,
  addBrandSubCategoryRiserGraphData,
  storeCleanUpBrand,
  addBrandBrandRiserGraphData
} = BrandAnalysisSlice.actions;

export default BrandAnalysisSlice.reducer;
