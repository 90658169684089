import { createSlice } from "@reduxjs/toolkit";
export const FeedbackAnalysis = createSlice({
  name: "FeedbackAnalysis",

  initialState: {
    marketSelect: [],
    variantSelect: [],
    tab: 0,
    master_product_id: "",
    listingData: null,
    overViewData: {},
    negativeData: {},
    positiveData: {},
    uiData: {},
    product: {},
    listingMarketplace: null
  },

  reducers: {
    addMarketSelect: (state, action) => {
      state.marketSelect = action.payload;
    },

    addOverviewData: (state, action) => {
      state.overViewData = action.payload;
    },

    addNegativeData: (state, action) => {
      state.negativeData = action.payload;
    },

    addPositiveData: (state, action) => {
      state.positiveData = action.payload;
    },

    addVariantSelect: (state, action) => {
      state.variantSelect = action.payload;
    },

    addMasterProduct: (state, action) => {
      state.product = action.payload;
    },

    addTab: (state, action) => {
      state.tab = action.payload;
    },

    addListingData: (state, action) => {
      state.listingData = action.payload;
    },
    addListingMarketplace: (state, action) => {
      state.listingMarketplace = action.payload;
    },
    addUiData: (state, action) => {
      state.uiData = action.payload;
    },

    storeCleanupFeedback: (state, action) => {
      state.tab = 0;
      state.variantData = [];
      state.variantSelect = [];
      state.marketSelect = [];
      state.overViewData = {};
      state.positiveData = [];
      state.negativeData = [];
      state.master_product_id = [];
      state.product = [];
      state.listingData = [];
    }
  }
});

export const {
  addMarketSelect,
  addVariantSelect,
  addPositiveData,
  addNegativeData,
  addOverviewData,
  addMasterProduct,
  storeCleanupFeedback,
  addTab,
  addListingData,
  addUiData,
  addListingMarketplace
} = FeedbackAnalysis.actions;
export default FeedbackAnalysis.reducer;
